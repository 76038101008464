html {
  margin: 2% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Светлый фон */
}

/* Настройка для фона */
body::before {
  content: "";
  position: absolute; /* Абсолютное позиционирование для фона */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../public/background.svg"); /* Указываем путь к вашему SVG */
  background-size: cover; /* Масштабируем изображение, чтобы заполнить весь экран */
  background-repeat: no-repeat; /* Не повторять изображение */
  background-position: center; /* Центрируем изображение */
  z-index: -1; /* Отправляем фон назад по слою */
}

.container {
  text-align: center;
  color: #333; /* Темно-серый цвет для текста */
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Белый фон с 50% прозрачностью */
  border: 1px solid #ccc; /* Светло-серая граница */
  border-radius: 8px; /* Закругленные углы */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Небольшая тень */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Увеличенный зазор между элементами */
  padding: 20px; /* Внутренние отступы */
  width: 400px; /* Ширина формы */
}

.container h1 {
  font-size: 24px; /* Размер заголовка */
  color: #383838; /* Цвет заголовка */
  margin: 0; /* Отступы у заголовка ноль*/
}

.container input {
  padding: 10px; /* Внутренние отступы для полей ввода */
  font-size: 16px; /* Размер шрифта для полей ввода */
  border: 1px solid #ccc; /* Светло-серая граница для полей */
  border-radius: 4px; /* Закругленные углы для полей ввода */
}

.password-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Устанавливаем ширину контейнера для согласованности */
}

.password-container input {
  flex: 1; /* Позволяет инпуту занять все пространство */
  padding-right: 45px; /* Увеличенный правый отступ для глазика */
}

.password-icon {
  position: absolute;
  right: 10px; /* Положение глазика */
  cursor: pointer;
  width: 25px; /* Ширина глазика */
  height: 25px; /* Высота глазика */
  background: url("../public/eye-svgrepo-com.png") no-repeat center center; /* Относительный путь к изображению */
  background-size: contain; /* Размер изображения */
}

.password-icon.active {
  background: url("../public/eye-off-svgrepo-com.png") no-repeat center center; /* Относительный путь к изображению */
  background-size: contain; /* Размер изображения */
}

.container button {
  padding: 10px; /* Внутренние отступы для кнопки */
  font-size: 16px; /* Размер шрифта для кнопки */
  font-weight: bold; /* Жирный шрифт для кнопки */
  background-color: #589652; /* Темно-зеленый цвет фона кнопки */
  color: white; /* Цвет текста кнопки */
  border: none; /* Без границы */
  border-radius: 4px; /* Закругленные углы для кнопки */
  cursor: pointer; /* Указатель при наведении */
}

.container button:hover {
  background-color: #119105; /* Темнее цвет кнопки при наведении */
}

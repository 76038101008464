.userList {
  text-align: center;
  color: #333; /* Темно-серый цвет для текста */
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Белый фон с 50% прозрачностью */
  border: 1px solid #ccc; /* Светло-серая граница */
  border-radius: 8px; /* Закругленные углы */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Небольшая тень */
  display: flex;
  flex-direction: column;
  gap: 15px; /* Увеличенный зазор между элементами */
  padding: 20px; /* Внутренние отступы */
  width: 700px; /* Ширина формы */
}

.userList h1 {
  font-size: 24px; /* Размер заголовка */
  color: #383838; /* Цвет заголовка */
  margin: 0; /* Отступы у заголовка ноль*/
}

.userList h2 {
  font-size: 18px; /* Размер заголовка */
  color: #383838; /* Цвет заголовка */
  margin: 0; /* Отступы у заголовка ноль*/
}

.userList input {
  padding: 10px; /* Внутренние отступы для полей ввода */
  font-size: 16px; /* Размер шрифта для полей ввода */
  border: 1px solid #ccc; /* Светло-серая граница для полей */
  border-radius: 4px; /* Закругленные углы для полей ввода */
}

.userList button {
  padding: 10px; /* Внутренние отступы для кнопки */
  font-size: 16px; /* Размер шрифта для кнопки */
  font-weight: bold; /* Жирный шрифт для кнопки */
  background-color: #cf5d5d; /* Темно-зеленый цвет фона кнопки */
  color: white; /* Цвет текста кнопки */
  border: none; /* Без границы */
  border-radius: 4px; /* Закругленные углы для кнопки */
  cursor: pointer; /* Указатель при наведении */
}

.userList button:hover {
  background-color: #ca2525; /* Темнее цвет кнопки при наведении */
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.user-table tr:hover {
  background-color: #f1f1f1;
}

button {
  margin-left: 5px;
}

caption {
  padding: 10px; /* Внутренние отступы для кнопки */
  font-size: 20px; /* Размер шрифта*/
  font-weight: bold; /* Жирный шрифт для кнопки */
}
